import axios, { AxiosResponse } from 'axios'
import React, { useCallback, useMemo } from 'react'
import { useAxios } from 'react-hooks-async'
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import './unit-code.scss'
import {
  FilePathService,
  Loading,
  escapeSlashes,
  ResultsCard,
  SpecificationsDTO,
} from '../../common'

interface Props {
  qualification: string
  specification: string
}

export const UnitCodes: React.FC<Props> = ({
  qualification,
  specification,
}) => {
  const history = useHistory()

  const navigate = useCallback(
    (unitCode: string) =>
      history.push(
        `/aam/${qualification}/${escapeSlashes(specification)}/${escapeSlashes(
          unitCode
        )}`
      ),
    [history, qualification, specification]
  )

  const getUnitCodesConfig = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/${FilePathService.encode(
        qualification
      )}/${FilePathService.encode(specification)}/unitCodes`,
    }),
    [qualification, specification]
  )

  const getUnitCodesTask = useAxios<AxiosResponse<SpecificationsDTO>>(
    axios,
    getUnitCodesConfig
  )

  const unitCodes = useMemo(() => getUnitCodesTask.result?.data.items || [], [
    getUnitCodesTask.result,
  ])

  if (getUnitCodesTask.started && getUnitCodesTask.pending) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }

  return (
    <Container className="unit-code-container container">
      <Row className="justify-content-center mx-auto">
        <Col>
          <h5 className="title">{specification}</h5>
          <p className="unit-code-info">
            Click on a button to view the additional assessment material for
            that unit/component.
          </p>
        </Col>
      </Row>
      <div className="cards-container">
        {unitCodes.map((unit, index) => (
          <div className="unit-card" key={unit}>
            <ResultsCard
              title={unit}
              buttonText="See files"
              onClick={() => navigate(unit)}
              dataTestIdSuffix={index}
            />
          </div>
        ))}
      </div>
    </Container>
  )
}
