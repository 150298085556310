import React, { useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import {
  CentreSyllabus,
  isCambridgeInternational,
  isOCR,
  SyllabusCandidate,
} from '../../../common'
import { candidateColumnSizes, ICandidateUpdate } from '../constants'

interface ICandidateFull {
  candidate: SyllabusCandidate
  isErroneous: boolean
}

export const CandidateFull: React.FC<ICandidateFull> = ({
  candidate: { candidateNumber, name, dob, grade, optionCode },
  isErroneous,
}): JSX.Element => {
  const columnCount = 4
  const sizes = useMemo(() => candidateColumnSizes[columnCount], [columnCount])

  const title = useMemo(
    () =>
      isCambridgeInternational() && optionCode
        ? `${name} (${optionCode})`
        : name,
    [name, optionCode]
  )

  return (
    <Row
      className={
        isErroneous
          ? 'reddish-background candidate-row px-25 py-3'
          : 'candidate-row px-25 py-3'
      }
      data-testid="candidate-row"
    >
      <Col className="text-left name" md={sizes[0]}>
        {title}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[1]}>
        {dob}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[2]}>
        {candidateNumber}
      </Col>
      <Col className="text-center" md={sizes[3]}>
        <span className="candidate-grade px-3 py-25">{grade}</span>
      </Col>
    </Row>
  )
}
