import React from 'react'
import { Row, Col, Label } from 'reactstrap'

import { faLevelUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Candidate } from '../../candidate/candidate'
import { CentreSyllabus, SyllabusCandidate } from '../../../../common/types'
import { ICandidateUpdateExtended } from '../../constants'
import { isGraded } from '../candidates-list-utils'

interface ISortableCandidatesProps {
  sortedCandidates: SyllabusCandidate[]
  showErrors: boolean
  candidatesPatchingProgress: { [key: string]: string | undefined }
  gradeFilter: string
  viewOnly: boolean
  patchCandidate: (change: ICandidateUpdateExtended) => void
  clearPatchingErrors: (ids?: string[]) => void
}

export const SortableCandidates: React.FC<ISortableCandidatesProps> = ({
  sortedCandidates,
  showErrors,
  candidatesPatchingProgress,
  gradeFilter,
  viewOnly,
  patchCandidate,
  clearPatchingErrors,
}) => {
  const handleChange = (candidate: SyllabusCandidate) => (change: any) =>
    patchCandidate({
      id: candidate.id,
      ...change,
    })

  return (
    <>
      {sortedCandidates.map((c, idx) => (
        <React.Fragment key={idx}>
          <Candidate
            key={`candidate-${c.id}`}
            isErroneous={showErrors && isGraded(c)}
            candidate={c}
            filter={gradeFilter}
            patchingProgress={candidatesPatchingProgress[c.id]}
            onChange={handleChange(c)}
            viewOnly={viewOnly}
          />
          {(candidatesPatchingProgress[c.id] || '').startsWith('error') && (
            <Row
              className="m-0 px-25 py-1 bg-danger text-white font-weight-bold pointer"
              onClick={() => clearPatchingErrors([c.id])}
            >
              <Col className="m-0">
                <FontAwesomeIcon
                  className="ml-2 mr-3 d-inline-flex"
                  rotation={90}
                  icon={faLevelUp}
                />
                <span>
                  {
                    'Grade change can not be completed for some reason, please try again.'
                  }
                </span>
              </Col>
            </Row>
          )}
        </React.Fragment>
      ))}
      {sortedCandidates.length === 0 && (
        <Row>
          <Col className="text-center">
            <Label
              className="font-weight-bold mt-3 text-secondary"
              style={{ fontSize: '24px' }}
            >
              No results
            </Label>
          </Col>
        </Row>
      )}
    </>
  )
}
