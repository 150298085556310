import React, { useEffect, useMemo, useState } from 'react'

import { Button, Label, Spinner } from 'reactstrap'
import {
  ProjectModal,
  RsModalHeader,
  RsModalBody,
  RsModalFooter,
  Title,
  InstructionMaterialDTO,
  SyllabusCandidate,
  PathParam,
} from '../../common'

import './bulk-x-grade.scss'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import useReactRouter from 'use-react-router'

interface IBulkSetXGradeModal {
  disabled: boolean
  header: string
  subheader: string
  ancillery: string
  candidates: SyllabusCandidate[]
  onSuccess: () => void
}

export const BulkSetXGrade: React.FC<IBulkSetXGradeModal> = ({
  disabled,
  header,
  subheader = '',
  ancillery,
  candidates = [],
  onSuccess,
}) => {
  const { match } = useReactRouter<PathParam>()
  const centreId = match.params.id
  const syllabusId = match.params.syllabusId

  const [modalOpen, setModalOpen] = useState(false)

  const bulkSetXGradeUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/bulk-set-x-grade/centre/${centreId}/syllabus/${syllabusId}`,
      method: 'patch',
    }
  }, [centreId, syllabusId])

  const bulkSetXGradeTask = useAsyncTaskAxios<
    AxiosResponse<InstructionMaterialDTO>
  >(axios, bulkSetXGradeUrl)

  useEffect(() => {
    if (bulkSetXGradeTask.result) {
      setModalOpen(false)
      onSuccess()
    }
  }, [bulkSetXGradeTask.result])

  const candidatesWithoutGrades = useMemo(
    () => candidates.filter((candidate) => !candidate.grade).length,
    [candidates]
  )

  return (
    <>
      <Button
        disabled={disabled}
        className="mr-3"
        color="primary"
        onClick={() => setModalOpen(true)}
        data-testid="candidates-search-line-set-list-complete-button"
      >
        Set list as complete
      </Button>
      <ProjectModal
        className="hoc-approval-modal"
        centered
        isOpen={modalOpen}
        dataTestid="bulk-x-grade-modal"
      >
        <RsModalHeader
          toggle={() => setModalOpen(false)}
          className="hoc-approval-modal-header bg-white px-5 pt-5 pb-25 modal-md"
        >
          <Title title={header} subTitle={subheader} ancillery={ancillery} />
        </RsModalHeader>
        <RsModalBody className="hoc-approval-modal-body px-5">
          <Label className="font-larger mb-1 modal-margin font-weight-bold">
            Set list as complete
          </Label>
          <div className="mt-0 modal-margin">
            <div className="modal-body-text">
              <div>
                There are currently {candidatesWithoutGrades} candidates that
                have no grades added for this qualification. Do you have any
                more grades to add?
              </div>
              <div>
                Candidates with no grades will be set to ‘X’ to indicate they
                have no grades for submission, eg they are not completing the
                qualification yet. You will be able to review the grades before
                you send to your Head of Centre for approval.
              </div>
            </div>
          </div>
        </RsModalBody>
        <RsModalFooter className="hoc-approval-modal-footer px-5 pb-5">
          <Button
            onClick={() => setModalOpen(false)}
            color="e4"
            size="lg"
            className="footer-btn_cancel"
          >
            Cancel
          </Button>
          <Button color="primary" size="lg" onClick={bulkSetXGradeTask.start}>
            {bulkSetXGradeTask.pending && bulkSetXGradeTask.started ? (
              <Spinner className="mr-2" size="sm" />
            ) : (
              'Yes, set list as complete'
            )}
          </Button>
        </RsModalFooter>
      </ProjectModal>
    </>
  )
}
