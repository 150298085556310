import { useMemo } from 'react'
import { useAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { LocalConfig } from '../types'

const useLocalConfig = () => {
  const getConfigMemo = useMemo(
    () => ({
      url:
        process.env.REACT_APP_CONFIGPATH ||
        `/config.json?${new Date().getTime()}`,
    }),
    []
  )

  const getConfigTask = useAxios<AxiosResponse<LocalConfig>>(
    axios,
    getConfigMemo
  )

  return {
    result: getConfigTask.result?.data,
  }
}

export default useLocalConfig
