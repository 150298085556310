import React, { useCallback } from 'react';
import { useBackButton } from '../../hooks';
import { useAuth0, isCAAdmin, isFunction } from '../../services';
import { DICTIONARY, BackButtonData, BackButtonDataFn, NULL } from './common';

interface IBackButtonDictionary {
  update: (route: string, params: { [key: string]: string }) => void
}
export const useBackButtonRouting = (): IBackButtonDictionary => {
  const { setData } = useBackButton()
  const { user } = useAuth0()

  const update = useCallback((route: string, params) => {
    const dictionaryEntry = DICTIONARY[route] || DICTIONARY.DEFAULT

    const newState = !isFunction(dictionaryEntry)
      ? (dictionaryEntry as BackButtonData)
      : (dictionaryEntry as BackButtonDataFn)({
        roleCAAdmin: !!(user && isCAAdmin(user)),
        params
      });
    if (newState) {
      setData(newState === NULL ? null : newState);
    }
  }, [user, setData]);

  return { update };
};
