import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'
import {
  CentreSyllabus,
  isCambridgeInternational,
  isOCR,
  SyllabusCandidate,
} from '../../../common'
import {
  candidateColumnSizes,
  ICandidateUpdate,
  UNRANKED_GRADE,
} from '../constants'
import { GradeSelector } from '../utilities/grade-selector/grade-selector'

interface ICandidateGradingFull {
  candidate: SyllabusCandidate
  patchingProgress?: string
  isErroneous: boolean
  viewOnly: boolean
  onChange: (update: ICandidateUpdate) => void
}

export const CandidateGrading: React.FC<ICandidateGradingFull> = ({
  candidate: { candidateNumber, name, grade, optionCode, allowedGrades },
  patchingProgress,
  isErroneous,
  viewOnly,
  onChange,
}): JSX.Element => {
  const columnCount = 2
  const sizes = useMemo(() => candidateColumnSizes[columnCount], [columnCount])

  const [pendingGrade, setPendingGrade] = useState<string | undefined>()

  useEffect(() => {
    if (!patchingProgress) {
      setPendingGrade(undefined)
    }
  }, [patchingProgress, setPendingGrade])

  const title = useMemo(
    () =>
      isCambridgeInternational() && optionCode
        ? `${name} (${optionCode})`
        : name,
    [name, optionCode]
  )

  return (
    <Row
      className={
        isErroneous
          ? 'reddish-background candidate-row px-25 py-35'
          : 'candidate-row px-25 py-35'
      }
      data-testid="candidate-row"
    >
      <Col className="text-left name" md={sizes[0]}>
        <Label className="candidate-name mb-1" data-testid="candidate-name">
          {title}
        </Label>
        <Label className="candidate-uln mb-0">{candidateNumber}</Label>
      </Col>
      <Col className="d-flex justify-content-center" md={sizes[1]}>
        <GradeSelector
          value={grade}
          options={allowedGrades}
          pendingValue={
            patchingProgress === 'pending' ? pendingGrade : undefined
          }
          disabled={viewOnly}
          onChange={(val: string) => {
            setPendingGrade(val)
            onChange({ grade: val })
          }}
        />
      </Col>
    </Row>
  )
}
