import ReactGA from 'react-ga'
import axios from 'axios'
import { useMemo, useState } from 'react'
import { ICandidateUpdateExtended } from '../../components/candidates-list/constants'
import { CentreSyllabus, SyllabusCandidate } from '../types'

const usePatchCandidate = (
  syllabus: CentreSyllabus,
  cachedCandidates: SyllabusCandidate[],
  updateCachedCandidates: (
    fn: (candidates: SyllabusCandidate[]) => SyllabusCandidate[]
  ) => void
) => {
  const { id: syllabusId, centreId } = syllabus
  const candidatesBaseUrl = useMemo(
    () =>
      `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/candidates`,
    [centreId, syllabusId]
  )
  const [lastUpdate, updateLastUpdate] = useState<number>(
    Number(syllabus?.lastUpdated)
  )
  const [candidatesPatchingProgress, setCandidatesPatchingProgress] = useState<{
    [key: string]: string | undefined
  }>({})

  const patchCandidate = useMemo(
    () => (update: ICandidateUpdateExtended) => {
      ;(async () => {
        const { id, grade } = update
        if (candidatesPatchingProgress[id] === 'pending') return

        setCandidatesPatchingProgress((tasks) => ({
          ...tasks,
          [id]: 'pending',
        }))

        const cachedCandidate:
          | SyllabusCandidate
          | undefined = cachedCandidates.find(
          (candidate) => candidate.id === update.id
        )

        try {
          ReactGA.event({
            category: 'Grade Submission',
            action: 'Patch Candidate',
            label: syllabusId,
          })

          const { status, data } = await axios({
            method: 'patch',
            url: `${candidatesBaseUrl}/${encodeURIComponent(id)}`,
            data: {
              id,
              grade,
            },
          })

          if (status !== 200 && status !== 205) {
            throw new Error()
          }

          let updates = [data]
          if (status === 205) {
            const res = await axios.get(candidatesBaseUrl)
            if (res.status === 200) {
              updates = res.data
            }
          }

          updateCachedCandidates((cachedCandidates) =>
            cachedCandidates.map((it: any) => {
              const c = updates.find((itt: any) => itt.id === it.id)
              return !c
                ? it
                : {
                    ...it,
                    grade: c.grade,
                  }
            })
          )

          updateLastUpdate(Date.now())
          setCandidatesPatchingProgress((tasks) => ({
            ...tasks,
            [id]: undefined,
          }))
        } catch (err) {
          setCandidatesPatchingProgress((tasks) => ({
            ...tasks,
            [id]: `error-${cachedCandidate?.grade !== grade ? 'g' : 'r'}`,
          }))
        }
      })()
    },
    [
      candidatesBaseUrl,
      cachedCandidates,
      candidatesPatchingProgress,
      syllabusId,
    ]
  )

  const clearPatchingErrors = useMemo(
    () => (ids?: string[]) => {
      setCandidatesPatchingProgress((x) =>
        Object.entries(x).reduce(
          (acc, [key, val = '']) => ({
            ...acc,
            ...(val.startsWith('error') && (!ids || ids.includes(key))
              ? {}
              : { [key]: val }),
          }),
          {}
        )
      )
    },
    [setCandidatesPatchingProgress]
  )

  return {
    patchCandidate,
    candidatesPatchingProgress,
    clearPatchingErrors,
    lastUpdate,
  }
}

export default usePatchCandidate
