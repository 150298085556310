import React from 'react'
import { CentreSyllabus, SyllabusCandidate } from '../../../common'
import { CandidateFull } from './candidate-full'
import { CandidateGrading } from './candidate-grading'
import { ICandidateUpdate } from '../constants'

interface ICandidate {
  isErroneous: boolean
  candidate: SyllabusCandidate
  filter: string
  patchingProgress?: string
  onChange: (update: ICandidateUpdate) => void
  viewOnly: boolean
}

export const Candidate: React.FC<ICandidate> = ({
  isErroneous,
  candidate,
  filter,
  patchingProgress,
  onChange,
  viewOnly,
}) => {
  return filter === 'ALL' ? (
    <>
      <CandidateGrading
        isErroneous={isErroneous}
        candidate={candidate}
        viewOnly={viewOnly}
        patchingProgress={patchingProgress}
        onChange={onChange}
      />
    </>
  ) : (
    <CandidateFull isErroneous={isErroneous} candidate={candidate} />
  )
}
