import { useMemo } from 'react'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { SystemAvailabilityConfigDTO } from '../types'

const useSystemAvailabilityConfig = () => {
  const getSystemAvailabilityConfig = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/system-availability-config`,
    }),
    []
  )

  const getSystemAvailabilityConfigTask = useAsyncTaskAxios<
    AxiosResponse<SystemAvailabilityConfigDTO>
  >(axios, getSystemAvailabilityConfig)

  return {
    start: getSystemAvailabilityConfigTask.start,
    result: getSystemAvailabilityConfigTask.result?.data,
  }
}

export default useSystemAvailabilityConfig
