import React, { useMemo, useEffect } from 'react'
import { Container, Label, Nav, Row } from 'reactstrap'
import { NavTabs } from '../management/nav-tabs'
import { InformationRow } from '../management/information-row'
import { AddUserForm } from '../management/add-user-form/add-user-form'
import { User } from '../management/user/user'
import { useUserManagement } from '../management/use-user-management'
import {
  ROLES,
  isCAAdmin,
  isAdmin,
  ManagedUser,
  Loading,
  isHighTraffic,
  useAuth0,
  SimpleMessage,
  SimpleErrorMessage,
} from '../../common'
import ReactGA from 'react-ga'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { CAAdminBanner } from '../ca-admin-banner/ca-admin-banner'
import { AxiosError } from 'axios'

const conv = (s?: string) => (s ? s.toLowerCase() : 'Z')

interface IManagementPage {
  title: string
  description: JSX.Element
  role: string
  emptyListMessage: string
  buttonText: string
  centreId: string
  currentUsersLabel: string
}

export const ManagementPage: React.FC<IManagementPage> = ({
  title,
  description,
  role,
  emptyListMessage,
  buttonText,
  centreId,
  currentUsersLabel,
}): JSX.Element => {
  const { user } = useAuth0()

  const [
    usersList,
    createUserTask,
    listUserTask,
    getUserByEmail,
    deleteUser,
  ] = useUserManagement(centreId)
  const highTraffic = useMemo(() => {
    if (!createUserTask.error && !listUserTask.error) {
      return false
    }
    return (
      (createUserTask.error != null &&
        isHighTraffic((createUserTask.error as AxiosError).response)) ||
      (listUserTask.error != null &&
        isHighTraffic((listUserTask.error as AxiosError).response))
    )
  }, [createUserTask.error, listUserTask.error])

  useEffect(() => {
    if (createUserTask.error) {
      ReactGA.exception({
        description: `Failed to add user`,
        fatal: false,
      })
    }
  }, [createUserTask.error])

  useEffect(() => {
    if (listUserTask.error) {
      ReactGA.exception({
        description: `Failed to get users`,
        fatal: false,
      })
    }
  }, [listUserTask.error])

  const filteredUsers = useMemo(
    () => usersList.filter((it) => it.role === role),
    [role, usersList]
  )

  const centreAdmins = useMemo(
    () => usersList.filter((it) => it.role === ROLES.Admin),
    [usersList]
  )

  const sortedUsers = useMemo(
    () =>
      [...filteredUsers].sort((a: ManagedUser, b: ManagedUser) => {
        const nameDiff = conv(a.name).localeCompare(conv(b.name))
        if (nameDiff !== 0) return nameDiff

        const surnameDiff = conv(a.surname).localeCompare(conv(b.surname))
        if (surnameDiff !== 0) return surnameDiff

        const emailDiff = conv(a.email).localeCompare(conv(b.email))
        if (emailDiff !== 0) return emailDiff

        return (a.userId || '').localeCompare(b.userId || '')
      }),
    [filteredUsers]
  )

  return (
    <>
      <CAAdminBanner centreId={centreId} linkBack />
      {highTraffic && (
        <Nav className="error-nav bg-danger text-white font-weight-bold py-4">
          <Container>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              fixedWidth
              className="mr-3"
            />
            The system is experiencing high volumes of traffic at the moment.
            Please try your action again in a few minutes, thank you.
          </Container>
        </Nav>
      )}
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          {user && !isCAAdmin(user) && (
            <NavTabs centreId={centreId} activeRole={role} />
          )}
          <InformationRow title={title} description={description} />
          <AddUserForm
            buttonText={buttonText}
            centreId={centreId}
            getUserByEmail={getUserByEmail}
            createUserTask={createUserTask}
            role={role}
            usersInRole={filteredUsers.length}
          />
        </Container>
      </div>
      <Container>
        {!listUserTask.error && !listUserTask.pending && (
          <>
            {user && role === ROLES.Teacher && isAdmin(user) && (
              <Container className="mt-6 col-lg-18 ml-0">
                <Row>
                  <Label className="text-primary font-weight-bold h4">
                    Centre admins
                  </Label>
                </Row>
                {!listUserTask.pending &&
                  (centreAdmins || []).length > 0 &&
                  role === ROLES.Teacher && (
                    <>
                      {centreAdmins.map((it: ManagedUser) => (
                        <User
                          key={`management-user-${it.userId}`}
                          className="mt-3"
                          name={it.name}
                          allowDelete={false}
                          surname={it.surname}
                          role={it.role}
                          onDelete={(email) => deleteUser(email)}
                          email={it.email}
                          id={it.userId}
                          centreId={centreId}
                          confirmed={!!it.confirmed}
                        />
                      ))}
                    </>
                  )}
                {!listUserTask.pending && (centreAdmins || []).length === 0 && (
                  <SimpleMessage
                    className="mb-5 mt-3"
                    title="There are currently no centre admins at this centre"
                    icon={<FontAwesomeIcon icon={faUser} />}
                  />
                )}
              </Container>
            )}
            <Container className="mt-6 col-lg-18 ml-0">
              <Row>
                <Label className="text-primary font-weight-bold h4">
                  {currentUsersLabel}
                </Label>
              </Row>
              {!listUserTask.pending && (sortedUsers || []).length > 0 && (
                <>
                  {sortedUsers.map((it: ManagedUser) => (
                    <User
                      key={`management-user-${it.userId}`}
                      className="mt-3"
                      name={it.name}
                      surname={it.surname}
                      role={it.role}
                      allowDelete={true}
                      onDelete={(email) => deleteUser(email)}
                      email={it.email}
                      id={it.userId}
                      centreId={centreId}
                      confirmed={!!it.confirmed}
                    />
                  ))}
                </>
              )}
              {!listUserTask.pending && (sortedUsers || []).length === 0 && (
                <SimpleMessage
                  className="mb-5 mt-3"
                  title={emptyListMessage}
                  icon={<FontAwesomeIcon icon={faUser} />}
                />
              )}
            </Container>
          </>
        )}
        {listUserTask.error && (
          <SimpleErrorMessage
            allowPageRefresh
            title="Failed to get list of users for this centre"
          />
        )}

        {listUserTask.pending && (
          <Row className="mt-5 justify-content-center">
            <Loading />
          </Row>
        )}
      </Container>
    </>
  )
}
