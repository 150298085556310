import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { isFuture } from 'date-fns'
import querystring from 'querystring'
import useReactRouter from 'use-react-router'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import ReactGA from 'react-ga'

import { Container, Nav } from 'reactstrap'
import { useAsyncTaskAxios, useAxios } from 'react-hooks-async'
import {
  CentreSyllabus,
  PathParam,
  SyllabusCandidate,
  ValuationStatus,
  Loading,
  useAuth0,
  isHOC,
  syllabusInProgress,
  isCAAdmin,
  isForbidden,
  isBadRequest,
  useConfig,
  NotAvailable,
  SimpleMessage,
  SimpleErrorMessage,
  InlineErrorMessageNoBorder,
} from '../../common'
import { CandidatesListContainer } from '../candidates-list/candidates-list-container'
import getTextFromToken from '../../common/services/tokenised-text'
import { Redirect } from 'react-router-dom'
import { CAAdminBanner } from '../ca-admin-banner/ca-admin-banner'
import { SubjectPageNotice } from './components/subject-page-notice/subject-page-notice'
import { SubjectPageHeader } from './components/subject-page-header/subject-page-header'

export const SubjectPage: React.FC = (): JSX.Element => {
  const { config } = useConfig()
  const { match, location } = useReactRouter<PathParam>()
  const { user } = useAuth0()
  const [showErrors, setShowErrors] = useState(false)
  const [approvalDisabled, setApprovalDisabled] = useState(true)
  const [uploadedWithErr, setUploadedWithErr] = useState(false)
  const [syllabus, setSyllabus] = useState<CentreSyllabus | undefined>()

  const centreId = match.params.id
  const syllabusId = match.params.syllabusId

  const gradeFilter = useMemo<string>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )

    if (!qs.grade) {
      return 'ALL'
    }

    if (Array.isArray(qs.grade)) {
      return qs.grade[0]
    }

    return qs.grade
  }, [location])

  const [valuationStatus, setValuationStatus] = useState<string>('')
  useEffect(() => {
    setValuationStatus(syllabus?.valuationStatus || '')
  }, [syllabus])

  const syllabusClosed = useMemo(() => syllabus?.closed === true, [syllabus])

  const viewOnly = useMemo(
    () =>
      !syllabusInProgress(syllabus?.valuationStatus) ||
      (user !== undefined && isCAAdmin(user, true)) ||
      syllabusClosed,
    [user, syllabus, syllabusClosed]
  )

  const getCandidatesMemo = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/candidates`,
    }),
    [centreId, syllabusId]
  )
  const getCandidatesTask = useAxios<AxiosResponse<SyllabusCandidate[]>>(
    axios,
    getCandidatesMemo
  )

  useEffect(() => {
    if (uploadedWithErr) {
      setShowErrors(true)
    }
  }, [getCandidatesTask.result, uploadedWithErr])

  const patchSyllabusMemo = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}`,
      method: 'patch',
    }),
    [centreId, syllabusId]
  )
  const patchSyllabusTask = useAsyncTaskAxios<AxiosResponse<CentreSyllabus>>(
    axios,
    patchSyllabusMemo
  )

  const badPatch = useMemo(() => {
    if (!patchSyllabusTask.error) {
      return false
    }
    return isBadRequest((patchSyllabusTask.error as AxiosError).response)
  }, [patchSyllabusTask.error])

  const getCentreSyllabusMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}`,
    }
    // badPatch should be kept in the dependency array for approval or rejection fail
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreId, syllabusId, badPatch])

  const getCentreSyllabusTask = useAxios<AxiosResponse<CentreSyllabus>>(
    axios,
    getCentreSyllabusMemo
  )

  const forbidden = useMemo(() => {
    if (!getCentreSyllabusTask.error) {
      return false
    }
    return isForbidden((getCentreSyllabusTask.error as AxiosError).response)
  }, [getCentreSyllabusTask.error])

  useEffect(() => setSyllabus(getCentreSyllabusTask.result?.data), [
    getCentreSyllabusTask.result,
  ])

  const viewOnlyState = useMemo<string>(() => {
    if (user && isHOC(user) && valuationStatus !== ValuationStatus.APPROVED) {
      return 'hoc'
    }
    return valuationStatus
    // patchSyllabusTask.error should be kept in the dependency array for refreshing students list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuationStatus, user, patchSyllabusTask.error])

  useEffect(() => {
    if (patchSyllabusTask.result) {
      setSyllabus(patchSyllabusTask.result.data)
    }
  }, [patchSyllabusTask.result])

  const approvalDisabledCallback = (state: boolean) => {
    setApprovalDisabled(state)
  }

  const onUploadComplete = (uploadedWithErr: boolean) => {
    setUploadedWithErr(uploadedWithErr)
    getCentreSyllabusTask.start()
    getCandidatesTask.start()
  }

  const isDeadlineForSubmittingGradesPassed = useMemo(
    () =>
      (syllabusInProgress(syllabus?.valuationStatus) ||
        syllabus?.valuationStatus === ValuationStatus.SUBMITTED) &&
      syllabusClosed,
    [syllabus, syllabusClosed]
  )

  const onSendBack = useCallback(() => {
    ReactGA.event({
      category: 'Grade Submission',
      action: 'Reject',
      label: syllabusId,
    })

    patchSyllabusTask.start({
      data: {
        valuationStatus: ValuationStatus.COMPLETE,
      },
    })
  }, [patchSyllabusTask, syllabusId])

  const approveSyllabusHandler = useCallback(() => {
    ReactGA.event({
      category: 'Grade Submission',
      action: 'Approve',
      label: syllabusId,
    })
    patchSyllabusTask.start({
      data: { valuationStatus: ValuationStatus.APPROVED },
    })
  }, [patchSyllabusTask])

  if (centreId !== centreId.toUpperCase()) {
    return (
      <Redirect
        to={`/centres/${centreId.toUpperCase()}/syllabus/${syllabusId}`}
      />
    )
  }
  if (
    user &&
    !isCAAdmin(user) &&
    config.captureAvailableFrom &&
    isFuture(config.captureAvailableFrom)
  ) {
    return <NotAvailable availableTo={config.captureAvailableTo} />
  }

  if (syllabusId && getCentreSyllabusTask.result) {
    return (
      <>
        <CAAdminBanner centreId={centreId} linkBack />
        {showErrors && (
          <Nav className="error-nav bg-danger text-white font-weight-bold py-4">
            <Container>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                fixedWidth
                className="mr-3"
              />
              This {getTextFromToken('syllabus')} contains errors
            </Container>
          </Nav>
        )}

        {patchSyllabusTask.error && (
          <Nav className={`text-white font-weight-bold py-3 bg-danger`}>
            <Container>
              <InlineErrorMessageNoBorder
                isBgDanger
                title={`Failed to update ${getTextFromToken(
                  'syllabus'
                )}, please try again and if the problem persists please contact us`}
              />
            </Container>
          </Nav>
        )}
        {isDeadlineForSubmittingGradesPassed && (
          <Nav className={`text-white font-weight-bold py-3 bg-danger`}>
            <Container>
              <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
              (INCOMPLETE) The deadline for submitting grades{' '}
              <u>has now passed,</u> you can view and download the data.
            </Container>
          </Nav>
        )}
        {viewOnly &&
          !syllabusInProgress(valuationStatus) &&
          !syllabus?.closed && (
            <SubjectPageNotice
              syllabus={syllabus}
              viewOnlyState={viewOnlyState}
              syllabusId={syllabusId}
              inProgress={
                patchSyllabusTask.started && patchSyllabusTask.pending
              }
              approveSyllabus={approveSyllabusHandler}
              startTask={onSendBack}
            />
          )}
        <div
          className={`px-3 mb-5 ${
            viewOnly ? 'bg-lightButNotTooLight py-42' : 'bg-light py-45'
          }`}
        >
          <SubjectPageHeader
            approvalDisabled={approvalDisabled}
            viewOnlyState={viewOnlyState}
            syllabus={syllabus}
            viewOnly={viewOnly}
            showButtons={!!getCandidatesTask.result}
            onUploadComplete={onUploadComplete}
            setSyllabusCallback={setSyllabus}
          />
        </div>
        {getCandidatesTask.pending && (
          <Container>
            <SimpleMessage
              className="mb-5"
              icon={<Loading className="d-block mx-auto" />}
              title="Retrieving candidates..."
            />
          </Container>
        )}
        {!forbidden && !getCentreSyllabusTask.error && getCandidatesTask.error && (
          <Container>
            <SimpleErrorMessage
              title={`Failed to load candidates, please refresh and if the problem persists contact your system administrator`}
              allowPageRefresh
            />
          </Container>
        )}
        {syllabus && getCandidatesTask.result && (
          <>
            <CandidatesListContainer
              approvalDisabled={approvalDisabledCallback}
              gradeFilter={gradeFilter}
              candidates={getCandidatesTask.result?.data}
              showErrors={showErrors}
              toggleShowErrors={setShowErrors}
              lastUpdated={Number(syllabus?.lastUpdated)}
              viewOnly={viewOnly}
              syllabus={syllabus}
            />
          </>
        )}
      </>
    )
  }
  return (
    <Container className="mt-5">
      {getCentreSyllabusTask.pending && (
        <SimpleMessage
          className="mb-5"
          icon={<Loading className="d-block mx-auto" />}
          title={`Retrieving ${getTextFromToken('syllabus')}...`}
        />
      )}
      {forbidden && (
        <SimpleErrorMessage title="You do not have permission to submit for this centre" />
      )}
      {!forbidden && getCentreSyllabusTask.error && (
        <SimpleErrorMessage
          title={`Failed to load ${getTextFromToken(
            'syllabus'
          )}, please refresh and if the problem persists contact your system administrator`}
          allowPageRefresh
        />
      )}
    </Container>
  )
}
