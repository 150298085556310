import React, { useEffect, useMemo, useState } from 'react'
import { Container } from 'reactstrap'
import isEqual from 'lodash.isequal'
import { SyllabusCandidate, CentreSyllabus } from '../../common'
import {
  CandidatesSorter,
  candidatesSorting,
  toggleSorting,
} from './utilities/candidates-sorter/candidates-sorter'
import {
  buildGradingMap,
  candidatesFiltering,
} from './utilities/candidates-filter/candidates-filter'
import { CandidatesSearchLine } from './utilities/candidates-search-line'
import { ICandidateUpdateExtended } from './constants'
import {
  applySearchFilter,
  hasErrors,
  getErrorCounts,
  isGraded,
} from './utilities/candidates-list-utils'
import { TeacherAssessedGrades } from './utilities/teacher-assessed-grades/teacher-assessed-grades'
import { CandidateErrors } from './utilities/candidate-errors/candidate-errors'
import { SortableCandidates } from './utilities/sortable-candidates/sortable-candidates'
import './candidates-list.scss'

interface ICandidatesList {
  candidates: SyllabusCandidate[]
  patchCandidate: (change: ICandidateUpdateExtended) => void
  lastUpdated: number
  upToDate: boolean
  gradeFilter: string
  showErrors: boolean
  viewOnly: boolean
  candidatesPatchingProgress: { [key: string]: string | undefined }
  clearPatchingErrors: (ids?: string[]) => void
  toggleShowErrors: (newShow: boolean) => void
  approvalDisabled: (state: boolean) => void
  syllabus: CentreSyllabus
  isShowSetXGradeButton: boolean
  onSuccessBulkXGrade: () => void
}

export const CandidatesList: React.FC<ICandidatesList> = ({
  approvalDisabled,
  showErrors,
  toggleShowErrors,
  candidates,
  patchCandidate,
  lastUpdated,
  gradeFilter,
  candidatesPatchingProgress,
  clearPatchingErrors,
  upToDate,
  viewOnly,
  syllabus,
  isShowSetXGradeButton,
  onSuccessBulkXGrade,
}): JSX.Element => {
  const [searchFilter, setSearchFilter] = useState('')
  const [currentSorting, setCurrentSorting] = useState('NAME-down')
  const [showOnlyErrors, setShowOnlyErrors] = useState(false)
  const [grading, setGrading] = useState<string[]>([])

  const toggleSort = useMemo(
    () => (newSorting: string) => {
      setCurrentSorting(toggleSorting(newSorting, currentSorting))
    },
    [currentSorting, setCurrentSorting]
  )

  const gradingMap = useMemo(() => buildGradingMap(candidates), [candidates])

  const errors = useMemo(() => getErrorCounts(candidates), [candidates])

  const candidatesFilteredIds = useMemo(
    () =>
      candidatesFiltering(
        applySearchFilter(candidates, searchFilter),
        gradeFilter
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter, gradeFilter]
  )

  const filteredCandidates = useMemo(() => {
    let searchFiltered = candidates.filter((it) =>
      candidatesFilteredIds.includes(it.id)
    )
    if (showOnlyErrors) {
      searchFiltered = searchFiltered.filter((filteredCandidate) =>
        isGraded(filteredCandidate)
      )
    }
    return searchFiltered
  }, [candidates, candidatesFilteredIds, showOnlyErrors, gradeFilter])

  const candidatesSortingOrder = useMemo(
    () => candidatesSorting(filteredCandidates, currentSorting, { grading }),
    [currentSorting, grading, filteredCandidates]
  )

  const sortedCandidates = useMemo(
    () =>
      [...filteredCandidates].sort(
        (a, b) =>
          candidatesSortingOrder.indexOf(a.id) -
          candidatesSortingOrder.indexOf(b.id)
      ),
    [filteredCandidates, candidatesSortingOrder]
  )

  const gradedCandidatesCount = useMemo(
    () => candidates.filter((x) => x.grade).length,
    [candidates]
  )

  const filteredErrors = useMemo(() => {
    return getErrorCounts(filteredCandidates)
  }, [filteredCandidates])

  useEffect(() => {
    const grades = candidates.reduce(
      (acc: string[], curr) => [...acc, ...curr.allowedGrades],
      []
    )
    const newGrading = Array.from(new Set(grades).values())

    if (!isEqual(grading, newGrading)) {
      setGrading(newGrading)
    }
  }, [candidates, grading])

  useEffect(() => {
    if (!hasErrors(errors)) {
      toggleShowErrors(false)
    }
  }, [errors, toggleShowErrors])

  useEffect(() => {
    approvalDisabled(hasErrors(errors))
    if (!hasErrors(errors)) setShowOnlyErrors(false)
  }, [candidates, approvalDisabled, toggleShowErrors, errors])

  return (
    <Container className="candidates-list">
      <CandidatesSearchLine
        syllabus={syllabus}
        approvalDisabled={hasErrors(errors)}
        lastUpdated={lastUpdated}
        upToDate={upToDate}
        checkForErrorsClick={() => {
          if (hasErrors(errors)) {
            toggleShowErrors(true)
          }
        }}
        filterChanged={setSearchFilter}
        viewOnly={viewOnly}
        isShowSetXGradeButton={isShowSetXGradeButton}
        candidates={candidates}
        onSuccessBulkXGrade={onSuccessBulkXGrade}
      />
      <TeacherAssessedGrades
        gradeFilter={gradeFilter}
        candidates={candidates}
        gradedCandidatesCount={gradedCandidatesCount}
        showErrors={showErrors}
        grading={grading}
        gradingMap={gradingMap}
      />
      {showErrors && (
        <CandidateErrors
          showErrors={showErrors}
          showOnlyErrors={showOnlyErrors}
          filteredErrors={filteredErrors}
          setShowOnlyErrors={setShowOnlyErrors}
        />
      )}
      <CandidatesSorter
        currentSorting={currentSorting}
        filter={gradeFilter}
        showBottomBorder={sortedCandidates.length === 0}
        toggleSorting={toggleSort}
        syllabus={syllabus}
      />
      <SortableCandidates
        sortedCandidates={sortedCandidates}
        showErrors={showErrors}
        candidatesPatchingProgress={candidatesPatchingProgress}
        gradeFilter={gradeFilter}
        viewOnly={viewOnly}
        patchCandidate={patchCandidate}
        clearPatchingErrors={clearPatchingErrors}
      />
    </Container>
  )
}
