import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import {
  useBackButton,
} from '../../common'
import './back-link.scss'

export const BackLink = () => {
  const { data, setData } = useBackButton()
  const history = useHistory()

  const goBack = useCallback(() => {
    setData(null)
    if (data) {
      history.push(data.returnUrl)
    }
  }, [data, history, setData])

  if (!data) return null;

  return (
    <div
      className="back-button"
      data-testid="back-button"
      onClick={goBack}
    >
      <span>
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="mr-2 text-secondary"
        />
        <span className="font-weight-bold text-secondary">
          <span className="d-none d-md-inline">{` ${data.text}`}</span>
        </span>
      </span>
    </div>
  )
}
