import React, { FC, useEffect, useState } from 'react'
import usePublicRemoteConfig from '../../common/hooks/use-public-remote-config'
import useSystemAvailabilityConfig from '../../common/hooks/use-system-availability-config'
import useLocalConfig from '../../common/hooks/use-local-config'
import { ConfigState, SystemAvailabilityConfig } from '../../common'
import { ConfigContext } from '../../common/context/config-context'
import { parseSystemAvailableEnum } from '../../common'

export const ConfigProvider: FC = ({ children }): JSX.Element => {
  const { result: localConfig } = useLocalConfig()

  const [config, setConfig] = useState<ConfigState>({
    mainConfigLoaded: false,
    systemAvailabilityConfigLoaded: false,
    config: {},
    loadSystemAvailabilityConfig: () => {},
  })

  useEffect(() => {
    if (localConfig) {
      setConfig((prev) => ({
        ...prev,
        mainConfigLoaded: true,
        config: { ...prev.config, ...localConfig },
      }))
    }
  }, [localConfig])

  const {
    result: publicConfig,
    loaded: publicConfigLoaded,
  } = usePublicRemoteConfig()

  useEffect(() => {
    if (publicConfigLoaded) {
      setConfig((prev) => ({
        ...prev,
        config: {
          ...prev.config,
          systemAvailability: parseSystemAvailableEnum(publicConfig),
        },
      }))
    }
  }, [publicConfig, publicConfigLoaded])

  const {
    start: getSystemAvailabilityConfig,
    result: systemAvailabilityConfig,
  } = useSystemAvailabilityConfig()

  useEffect(() => {
    setConfig((config) => ({
      ...config,
      loadSystemAvailabilityConfig: getSystemAvailabilityConfig,
    }))
  }, [getSystemAvailabilityConfig])

  useEffect(() => {
    if (systemAvailabilityConfig) {
      const systemAvailabilityConvertedConfigs = Object.entries(
        systemAvailabilityConfig
      ).reduce<SystemAvailabilityConfig>(
        (acc, [key, val]) => ({
          ...acc,
          [key]: new Date(val),
        }),
        {} as SystemAvailabilityConfig
      )

      setConfig((prev) => ({
        ...prev,
        systemAvailabilityConfigLoaded: true,
        config: { ...prev.config, ...systemAvailabilityConvertedConfigs },
      }))
    }
  }, [systemAvailabilityConfig])

  return (
    <ConfigContext.Provider value={config}>
      {localConfig && children}
    </ConfigContext.Provider>
  )
}
