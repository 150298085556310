import React, { useEffect, useMemo, useState } from 'react'
import { ICandidateUpdateExtended } from './constants'

import { CentreSyllabus, SyllabusCandidate, isOCR } from '../../common'

import { CandidatesList } from './candidates-list'
import { ChangingGradeModal } from './modal/changing-grade-modal'
import { IChangeRequest } from './types'
import usePatchCandidate from '../../common/hooks/use-patch-candidate'

interface ICandidatesListContainer {
  gradeFilter: string
  candidates: SyllabusCandidate[]
  lastUpdated: number
  showErrors: boolean
  viewOnly: boolean
  toggleShowErrors: (newShow: boolean) => void
  approvalDisabled: (status: boolean) => void
  syllabus: CentreSyllabus
}

export const CandidatesListContainer: React.FC<ICandidatesListContainer> = ({
  gradeFilter,
  showErrors,
  toggleShowErrors,
  candidates,
  viewOnly,
  approvalDisabled,
  syllabus,
}): JSX.Element => {
  const [cachedCandidates, updateCachedCandidates] = useState<
    SyllabusCandidate[]
  >(candidates)
  const [changeRequest, setChangeRequest] = useState<IChangeRequest | null>(
    null
  )
  const [showModal, setShowModal] = useState(false)

  const {
    patchCandidate,
    candidatesPatchingProgress,
    clearPatchingErrors,
    lastUpdate,
  } = usePatchCandidate(syllabus, cachedCandidates, updateCachedCandidates)

  useEffect(() => {
    updateCachedCandidates(candidates)
  }, [candidates])

  const onPatchRequest = useMemo(
    () => (update: ICandidateUpdateExtended) => {
      if (viewOnly) return
      const candidate = cachedCandidates.find((c) => c.id === update.id)
      if (!candidate) return

      if (candidate.grade && candidate.grade !== update.grade) {
        handleChangeGrade(candidate, update)
      } else {
        patchCandidate(update)
      }
    },
    [cachedCandidates, setChangeRequest, setShowModal, patchCandidate, viewOnly]
  )

  const handleChangeGrade = (
    candidate: SyllabusCandidate,
    update: ICandidateUpdateExtended
  ) => {
    if (isOCR()) {
      setChangeRequest(null)
      patchCandidate(update)
    } else {
      setChangeRequest({ candidate, update })
      setShowModal(true)
    }
  }

  const isShowSetXGradeButton = useMemo(() => {
    const isCandidatesWithoutGradeExist = cachedCandidates.some(
      (candidate) => !candidate.grade
    )
    return isOCR() && isCandidatesWithoutGradeExist
  }, [cachedCandidates])

  useEffect(() => {
    clearPatchingErrors()
  }, [clearPatchingErrors, gradeFilter])

  const onSuccessBulkXGrade = () => {
    updateCachedCandidates((cachedCandidates) =>
      cachedCandidates.map((candidate) =>
        candidate.grade ? candidate : { ...candidate, grade: 'X' }
      )
    )
  }

  const changeGrade = () => {
    if (changeRequest) {
      patchCandidate(changeRequest.update)
      setShowModal(false)
    }
    setChangeRequest(null)
  }

  return (
    <div>
      <CandidatesList
        approvalDisabled={approvalDisabled}
        gradeFilter={gradeFilter}
        showErrors={showErrors}
        toggleShowErrors={toggleShowErrors}
        candidates={cachedCandidates}
        candidatesPatchingProgress={candidatesPatchingProgress}
        clearPatchingErrors={clearPatchingErrors}
        patchCandidate={onPatchRequest}
        lastUpdated={lastUpdate}
        upToDate={
          Object.values(candidatesPatchingProgress).filter(
            (x) => x === 'pending'
          ).length === 0
        }
        viewOnly={viewOnly}
        syllabus={syllabus}
        isShowSetXGradeButton={isShowSetXGradeButton}
        onSuccessBulkXGrade={onSuccessBulkXGrade}
      />
      {changeRequest && (
        <ChangingGradeModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          changeRequest={changeRequest}
          changeGrade={changeGrade}
        />
      )}
    </div>
  )
}
