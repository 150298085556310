import axios, { AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { PreSignedUploadUrl } from '../types'
import { useDownloadFile } from './use-download-file'

const useTemplateDownload = (
  centreId: string,
  syllabusId: string,
  syllabusCode: string
) => {
  const getCandidatesCsvUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/candidates/csv`,
    }
  }, [centreId, syllabusId])

  const getCandidatesCsvResult = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getCandidatesCsvUrl)

  const download = useDownloadFile({
    fileUrl: `${getCandidatesCsvResult.result?.data.url}`,
    fileName: `${centreId}_${syllabusCode}.csv`,
  })

  useAsyncRun(getCandidatesCsvResult.result && download)

  const downloadPending = useMemo(
    () =>
      (getCandidatesCsvResult.pending && getCandidatesCsvResult.started) ||
      (getCandidatesCsvResult.result != null &&
        download.pending &&
        download.started),
    [download, getCandidatesCsvResult]
  )

  return {
    start: getCandidatesCsvResult.start,
    downloadPending,
    result: download.result,
    error: download.error,
  }
}

export default useTemplateDownload
