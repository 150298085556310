import React, { useMemo, useState } from 'react'
import querystring from 'querystring'
import useReactRouter from 'use-react-router'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useAxios } from 'react-hooks-async'
import { Col, Container, Row } from 'reactstrap'

import { isFuture } from 'date-fns'
import {
  CentreSyllabus,
  CentreSyllabusDto,
  ValuationStatus,
  Loading,
  isForbidden,
  isCAAdmin,
  syllabusInProgress,
  useAuth0,
  useConfig,
  withCentreId,
  NotAvailable,
  SearchBar,
  SimpleErrorMessage,
  SimpleMessage,
  withExpiration,
} from '../../common'
import { SyllabusProgress } from '../syllabus-progress/syllabus-progress'
import './centre-syllabus-page.scss'
import getTextFromToken from '../../common/services/tokenised-text'
import { CAAdminBanner } from '../ca-admin-banner/ca-admin-banner'
import { CentreSyllabusPageNotice } from './components/centre-syllabus-page-notice/centre-syllabus-page-notice'
import { CentreSyllabusPageHeader } from './components/centre-syllabus-page-header/centre-syllabus-page-header'
import { CentreSyllabusPageFilter } from './components/centre-syllabus-page-filter/centre-syllabus-page-filter'
import { CentreSyllabusPageUpdatedDate } from './components/centre-syllabus-page-updated-date/centre-syllabus-page-updated-date'
import { CentreSyllabusPageNotificationMessage } from './components/centre-syllabus-page-notification-message/centre-syllabus-page-notification-message'

const VALUATION_ORDER = [
  '---',
  ValuationStatus.SUBMITTED,
  ValuationStatus.COMPLETE,
  ValuationStatus.INCOMPLETE,
  ValuationStatus.NOT_STARTED,
  ValuationStatus.APPROVED,
]

const SORT_SYLLABUS = (a: CentreSyllabus, b: CentreSyllabus) =>
  a.syllabusName?.localeCompare(b.syllabusName) ||
  a.syllabusCode?.localeCompare(b.syllabusCode) ||
  (VALUATION_ORDER.indexOf(a.valuationStatus) || 100) -
    (VALUATION_ORDER.indexOf(b.valuationStatus) || 100) ||
  a.qualification?.localeCompare(b.qualification)

export const CentreSyllabusPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => {
  const { config } = useConfig()

  const [searchFilter, setSearchFilter] = useState('')
  const [showReopenedOnly, setShowReopenedOnly] = useState(true)
  const { location } = useReactRouter()
  const { user } = useAuth0()
  const getCentreSyllabusMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses`,
    }
  }, [centreId])

  const getCentreSyllabusTask = useAxios<AxiosResponse<CentreSyllabusDto[]>>(
    axios,
    getCentreSyllabusMemo
  )

  const reopenedCount = useMemo(() => {
    return getCentreSyllabusTask.result
      ? getCentreSyllabusTask.result?.data.filter(
          (x) =>
            !x.closed &&
            (syllabusInProgress(x.valuationStatus) ||
              x.valuationStatus === ValuationStatus.SUBMITTED)
        ).length
      : 0
  }, [getCentreSyllabusTask.result])

  const submittingClosed = useMemo(() => {
    return getCentreSyllabusTask.result?.data.some((e) => e.closed)
  }, [getCentreSyllabusTask.result])

  const qualificationFilter = useMemo<string>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )

    if (!qs.qualification) {
      return 'All Quals'
    }

    if (Array.isArray(qs.qualification)) {
      return qs.qualification[0]
    }

    return qs.qualification
  }, [location])

  const forbidden = useMemo(() => {
    if (!getCentreSyllabusTask.error) {
      return false
    }
    return isForbidden((getCentreSyllabusTask.error as AxiosError).response)
  }, [getCentreSyllabusTask.error])

  const centres = useMemo<CentreSyllabus[]>(() => {
    if (!getCentreSyllabusTask.result) {
      return []
    }

    return getCentreSyllabusTask.result.data.map((centreSyllabusDto) => ({
      ...centreSyllabusDto,
      percentageCompleted:
        (100 * centreSyllabusDto.gradedCandidates) /
        centreSyllabusDto.totalCandidates,
    }))
  }, [getCentreSyllabusTask.result])

  const [statusFilter, setStatusFilter] = useState('ALL')

  const filtered = useMemo(() => {
    let filtered = centres
      .filter(
        (x) =>
          qualificationFilter === 'All Quals' ||
          x.qualification === qualificationFilter
      )
      .filter(
        (x) =>
          statusFilter === 'ALL' ||
          x.valuationStatus === statusFilter ||
          (statusFilter === ValuationStatus.OPENED &&
            !x.closed &&
            x.valuationStatus !== ValuationStatus.APPROVED)
      )

    if (searchFilter.length > 0) {
      filtered = filtered.filter(
        (x) =>
          (x.syllabusName &&
            x.syllabusName
              .toLowerCase()
              .includes(searchFilter.toLowerCase())) ||
          (x.syllabusCode &&
            x.syllabusCode.toLowerCase().includes(searchFilter.toLowerCase()))
      )
    }
    return filtered
  }, [centres, qualificationFilter, searchFilter, statusFilter])

  const sorted = useMemo(() => {
    const list = [...filtered]
    list.sort(SORT_SYLLABUS)
    return list
  }, [filtered])

  if (
    user &&
    !isCAAdmin(user) &&
    config.captureAvailableFrom &&
    isFuture(config.captureAvailableFrom)
  ) {
    return (
      <>
        <NotAvailable availableTo={config.captureAvailableTo} />
      </>
    )
  }

  return (
    <div className="centre-syllabus">
      <CAAdminBanner centreId={centreId} />

      <CentreSyllabusPageNotificationMessage centres={centres} />

      {submittingClosed && (
        <CentreSyllabusPageNotice
          reopenedCount={reopenedCount}
          showReopenedOnly={showReopenedOnly}
          showSyllabuses={() => {
            setStatusFilter(ValuationStatus.OPENED)
            setShowReopenedOnly(false)
          }}
        />
      )}
      <CentreSyllabusPageHeader centreId={centreId} />
      <Container className="mt-5">
        {getCentreSyllabusTask.pending && (
          <SimpleMessage
            className="mb-5"
            icon={<Loading className="d-block mx-auto" />}
            title={`Retrieving ${getTextFromToken('syllabuses')}...`}
          />
        )}
        {forbidden && (
          <SimpleErrorMessage title="You do not have permission to submit for this centre" />
        )}
        {!forbidden && getCentreSyllabusTask.error && (
          <SimpleErrorMessage
            title={`Failed to load ${getTextFromToken(
              'syllabuses'
            )}, please refresh and if the problem persists contact your system administrator`}
            allowPageRefresh
          />
        )}
      </Container>
      <Container className="mt-5">
        {getCentreSyllabusTask.result &&
          getCentreSyllabusTask.result.data.length > 0 && (
            <>
              <Row className="my-5">
                <Col lg={11}>
                  <SearchBar
                    val={searchFilter}
                    placeholder={`Search for a ${getTextFromToken('syllabus')}`}
                    filterChanged={(newVal) => setSearchFilter(newVal)}
                    disableSearch={searchFilter.length < 5}
                    dataTestid="centre-syllabus-search-bar"
                  />
                </Col>
                <Col className="d-flex justify-content-end opacity-59 font-weight-semi-bold text-right">
                  <CentreSyllabusPageUpdatedDate centres={centres} />
                </Col>
              </Row>

              <CentreSyllabusPageFilter
                statusFilter={statusFilter}
                centres={centres}
                qualificationFilter={qualificationFilter}
                reopenedCount={reopenedCount}
                changeStatusFilter={setStatusFilter}
              />

              {sorted.map((qual, idx) => (
                <SyllabusProgress
                  key={`sylprog-${idx}`}
                  className="mb-45"
                  onUpdate={() => {
                    getCentreSyllabusTask.start()
                  }}
                  {...qual}
                />
              ))}
            </>
          )}

        {user && getCentreSyllabusTask.result && sorted.length === 0 && (
          <SimpleMessage
            title={`No ${getTextFromToken('syllabuses')} ${
              centres.length === 0
                ? ' found for this centre'
                : ' match the filter/search'
            }`}
            message={
              isCAAdmin(user)
                ? 'Please check that you have entered the correct Centre ID.'
                : undefined
            }
          />
        )}
      </Container>
    </div>
  )
}

export const CentreSyllabusPageWithRoute = withCentreId(CentreSyllabusPage)
export const CentreSyllabusPageWithRouteAndExpiration = withExpiration(
  CentreSyllabusPageWithRoute
)
