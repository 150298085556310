import React, { useState } from 'react'
import ReactGA from 'react-ga'
import useReactRouter from 'use-react-router'
import { Col, Container, Row } from 'reactstrap'
import {
  isOCR,
  usePresignedDownload,
  DownloadButton,
  SearchBar,
} from '../../common'
import './ca-admin-controls.scss'
import { OfqualDownloadReport } from './components/ofqual-download-report'

export const CambridgeAdminControls: React.FC = (): JSX.Element => {
  const { history: routerHistory } = useReactRouter()

  const {
    pending: centresProgressPending,
    download: downloadCentres,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/centres/progressUrl`,
    fileName: 'centres_download.csv',
  })

  const {
    pending: aamDownloadLogPending,
    download: downloadAAMLog,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/aam-download-log/url`,
    fileName: 'aam_download_log.csv',
  })

  const {
    pending: auditDownloadPending,
    download: downloadGradeSubmissionsAuditLog,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/auditlog/url`,
    fileName: 'grade_submissions_audit_log.csv',
  })

  const {
    pending: centreTagsReportDownloadPending,
    download: downloadCentreTagsReport,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/download-centre-tags-report`,
    fileName: 'centre-tags-report.csv',
  })

  const [searchInput, setSearchInput] = useState('')

  return (
    <div className="ca-admin-controls">
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row>
            <Col xs={24} md={8}>
              <h3 className={`h5 mb-1 font-weight-bold text-primary`}>
                Internal Dashboard
              </h3>
              <div>
                You can search{' '}
                <b>
                  <u>for a specific centre</u>
                </b>{' '}
                by it’s centre number and go to their dashboard from this page.
              </div>
            </Col>

            <Col className="text-right ca-buttons">
              {isOCR() && (
                <DownloadButton
                  text="Download AAM Log"
                  pending={aamDownloadLogPending}
                  download={() => {
                    ReactGA.event({
                      category: 'CA Support',
                      action: 'Download AAM Log',
                    })
                    downloadAAMLog()
                  }}
                  dataTestid="ca-admin-controls-download-aam-log-button"
                />
              )}
              <DownloadButton
                text="Download Submission Audit Log"
                pending={auditDownloadPending}
                download={() => {
                  ReactGA.event({
                    category: 'CA Support',
                    action: 'Download Audit Log',
                  })
                  downloadGradeSubmissionsAuditLog()
                }}
                dataTestid="ca-admin-controls-download-submission-audit-log-button"
              />
              <DownloadButton
                text="Download Centre Progress"
                pending={centresProgressPending}
                download={() => {
                  ReactGA.event({
                    category: 'CA Support',
                    action: 'Download Progress',
                  })
                  downloadCentres()
                }}
                dataTestid="ca-admin-controls-download-centre-progress-button"
              />
              <DownloadButton
                text="Download TAGS report"
                pending={centreTagsReportDownloadPending}
                download={() => {
                  ReactGA.event({
                    category: 'CA Support',
                    action: 'Download TAGS Report',
                  })
                  downloadCentreTagsReport()
                }}
                dataTestid="ca-admin-controls-download-tags-report-button"
              />
              <OfqualDownloadReport />
            </Col>
          </Row>
          <Row className="mt-5 align-items-center">
            <Col md={12}>
              <SearchBar
                val={searchInput}
                placeholder="Search for a centre"
                filterChanged={setSearchInput}
                onClick={() => routerHistory.push(`/centres/${searchInput}`)}
                disableSearch={searchInput.length < 5}
                dataTestid="ca-admin-controls-search-bar"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
